/**
 * Shared sso function space
 *
 */

export const getSSOCompanyInfo = (email, cb) => {
    var data = { email: email };
    var ajax_method = $f.mobile ? $f.mobile.ajax : $.ajax; // @jira:MOBILE-639
    // using regular $.ajax because this is shared between login.html and our mobile app.
    ajax_method({
        url: '/get_sso_company_info/',
        dataType: 'json',
        type: 'POST',
        cache: false,
        data: JSON.stringify(data),
        timeout: 20000,
        success: cb,
        error: cb
    });
};
