import * as $f_sso_utils from '../shared/sso';

/**
 * A separate bundle just for the login page so we can provide SSO utilities without the entire webapp HTML.
 */

window.$f = {
    utils: {
        sso: $f_sso_utils
    }
};
